 


.inline-block {
    position: relative;
    display: inline-block;
  }
  .inline-bot {
    position: relative;
    display: inline-block;
  }

.inline-block::after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 424px;
    height: 2px;
    background-color: #4981EC;
  }

.inline-bot::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 716px;
    height: 2px;
    background-color: #EF8C62;
  }
  @font-face {
    font-family: 'Lobster';
    src: url('./font/Lobster-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('./font/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter-light';
    src: url('./font/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}